
input:focus {
   outline: none;
}
textarea:focus{
   outline: none;
}

TextInput{
   border-bottom-width: 1px;
}

/* div , View {
   display: flex;
   flex-direction:column;
} */

