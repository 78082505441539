


@font-face {
       font-family: "myFont";
       src: url("./assets/font/Vazir-FD-WOL.eot") format("eot");
       src: url("./assets/font/Vazir-FD-WOL.ttf") format("ttf");
       src: url("./assets/font/Vazir-FD-WOL.woff") format("woff");
       /* src: url("./font/Yekan.eot") format("eot");
       src: url("./font/Yekan.ttf") format("ttf");
       src: url("./font/Yekan.woff") format("woff"); */
     }
     *{
       font-family: myFont;
     }
     body{
       direction: rtl;
       font-family: myFont;
     }
     
     *{
       font-family: myFont;
     }
     
     .mainContainer{
         display:flex;
         flex-direction: column;
         flex-grow: 1;
         flex:1 
       }
       
       .displayFlexRow{
         display: flex;
         flex-direction:row;
       }
       .displayFlexRowReverse{
         display: flex;
         flex-direction:row-reverse;
       }
       
       .displayFlexCol{
         display: flex;
         flex-direction:column;
       }
       
       .displayFlexGrow{
         flex-grow: 1;
         flex:1
       }
       
       .displayFlexCenter{
         justify-content: center;
         align-items: center;
       }
       
       .productComponentInfo{
         position:absolute ; 
         top:5px ; 
         left:5px ; 
         right:5px ; 
         bottom:5px ; 
         background-color:rgba(0,0,0,0.5) 
       }
       
       html , body{
         /* font-weight: bold; */
         font-size: 12;
         color:#263238;
         direction: rtl;
       }
     
       *{
         font-family: myFont;
       }
     
     
       table.myTable, th  , td {
         border:0px solid #eeeeee;
         border-collapse:collapse;
       }
     
       table.myTable tr:nth-child(even){
         background-color: #eeeeee;
       }
       table.myTable tr:nth-child(odd){
         background-color: #FFFFFF;
       }
       table.myTable tr:hover{
         background-color: #e1f5fe;
       }
     
       table thead tr th {
         /* you could also change td instead th depending your html code */
         position: sticky;
         z-index: 2;
         top: 0;
       }
     
       ::placeholder{
         color:"#a0a0a0" ; 
         opacity:0.6
       }
     
       ::-ms-input-placeholder{
         color:"#a0a0a0" ; 
         opacity:0.6
       }
     
     
     /* 
       width
     ::-webkit-scrollbar {
       width: 2px;
       height:2px
     }
     
     ::-webkit-scrollbar-track {
       background: #f1f1f1; 
     }
      
     ::-webkit-scrollbar-thumb {
       background: #888; 
       border-radius: 10px;
     }
     
     ::-webkit-scrollbar-thumb:hover {
       background: #555; 
     } */
     
     
     